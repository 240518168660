function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">
        <a id="ekartvizzit" href="https://ekartvizzit.com">
          e-Kartvizzit{" "}
        </a>
        tarafından ❤️ ile yapılmıştır.
      </p>
    </div>
  );
}
export default Footer;

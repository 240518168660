let variables = {
  mail: {
    link: "mailto:eniscantiryaki07@gmail.com",
  },
  linkedin: {
    link: "https://www.linkedin.com/in/eniscantiryaki",
  },
  instagram: {
    link: "https://www.instagram.com/eniscantiryaki",
  },
  behance: {
    link: "https://www.behance.net/eniscantiryakii",
  },
  whatsapp: {
    link: "https://api.whatsapp.com/send?phone=905050917950",
  },
  tel: {
    link: "tel:+905050917950",
  },
  user: {
    name: "Enis Can Tiryaki",
    occupation: "Grafik Tasarımcı @ Tırtıl Reklam",
    website: "eniscantiryaki.com",
  },
};

export default variables;

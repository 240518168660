import variables from "./config";

function CTA() {
  const buttons = [];

  // Butonları array'e ekle
  if (variables.mail?.link) {
    buttons.push(
      <a href={variables.mail.link} className="cta-btn" id="email" key="email">
        <box-icon name="envelope"></box-icon>
        <span className="cta-btn-text">Email</span>
      </a>
    );
  }

  if (variables.tel?.link) {
    buttons.push(
      <a
        href={variables.tel.link}
        className="cta-btn"
        id="telephone"
        key="telephone"
      >
        <box-icon name="phone"></box-icon>
        <span className="cta-btn-text">Telefon</span>
      </a>
    );
  }

  if (variables.instagram?.link) {
    buttons.push(
      <a
        href={variables.instagram.link}
        className="cta-btn"
        id="instagram"
        key="instagram"
      >
        <box-icon name="instagram" type="logo"></box-icon>
        <span className="cta-btn-text">Instagram</span>
      </a>
    );
  }

  if (variables.whatsapp?.link) {
    buttons.push(
      <a
        href={variables.whatsapp.link}
        className="cta-btn"
        id="whatsapp"
        key="whatsapp"
      >
        <box-icon name="whatsapp" type="logo"></box-icon>
        <span className="cta-btn-text">Whatsapp</span>
      </a>
    );
  }

  if (variables.behance?.link) {
    buttons.push(
      <a
        href={variables.behance.link}
        className="cta-btn"
        id="behance"
        key="behance"
      >
        <box-icon name="behance" type="logo"></box-icon>
        <span className="cta-btn-text">Behance</span>
      </a>
    );
  }

  if (variables.linkedin?.link) {
    buttons.push(
      <a
        href={variables.linkedin.link}
        className="cta-btn"
        id="linkedin"
        key="linkedin"
      >
        <box-icon name="linkedin" type="logo"></box-icon>
        <span className="cta-btn-text">LinkedIn</span>
      </a>
    );
  }

  // Tek veya çift sayıya göre hizalama
  const isOdd = buttons.length % 2 !== 0;

  return (
    <div className={`cta ${isOdd ? "single-column" : "double-column"}`}>
      {isOdd
        ? buttons.map((button) => (
            <div className="button-group" key={button.key}>
              {button}
            </div>
          ))
        : buttons
            .reduce((rows, button, index) => {
              if (index % 2 === 0) {
                rows.push([button]);
              } else {
                rows[rows.length - 1].push(button);
              }
              return rows;
            }, [])
            .map((row, index) => (
              <div className="button-group" key={index}>
                {row}
              </div>
            ))}
    </div>
  );
}

export default CTA;
